<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-layers2"
    :custom-class="state.modal.headerClasses"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="state.modal.updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row" ref="contactExportForm">
      <div class="form-group col-12">
        <label for="categoryName" class="col-form-label">
          Select a Range:
        </label>
        <Datepicker
          v-model="state.form.joiningDate.value"
          placeholder="Start Date - End Date"
          :format="formatDateRanger"
          range>
        </Datepicker>
        <FormErrorMsg :error="state.form.joiningDate.error" />
      </div>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { reactive, ref, watch } from 'vue';
import { formatDateRanger, makeCsv, downloadCsv } from '@/utils/Helper';
import http from '@/Plugins/http';
import { GET_CONTACT_LIST } from '@/utils/Urls';
import Toaster from '@/utils/Toaster';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ErrorHelper from '@/utils/ErrorHelper';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';

export default {
  name: 'ExportContactAction',
  components: { ModalComp, Datepicker, FormErrorMsg, FormInputHelp },
  emits: ['hide'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    const state = reactive({
      filename: 'Contact',
      modal: {
        show: false,
        title: 'Export Contact List',
        submitBtnText: 'Export',
        updating: false,
      },
      form: {
        submitting: false,
        joiningDate: {
          error: false,
          value: ''
        }
      },
    });

    const validationRules = {
      form: {
        joiningDate: {
          value: {
            required,
          }
        },
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.joiningDate.error = errors['form.joiningDate.value'] || false;
        },
        {
          'form.joiningDate.value': 'Join date-range'
        }
      );
    });

    const contactExportForm = ref(null);

    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    function handleClose () {
      emit('hide', false);
      resetForm();
    }

    const resetForm = () => {
      state.form.joiningDate.value = '';
    };

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      state.form.submitting = true;

      const joinDate = { joinDate: state.form.joiningDate.value }

      try {
        const responseData = await http.post(GET_CONTACT_LIST, joinDate);
        processDownloadableData(responseData);
        // close the modal
        handleClose();

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

      }

      state.form.submitting = false;
    }

     const processDownloadableData = (responseData) => {

      if (!responseData.data.data.length) {
        Toaster.error({
          title: 'OPS!',
          message: 'No contacts found for this range'
        });

        return;
      }

       const data = responseData.data.data.map(row => ({
         Name: row.name,
         Email: row.email,
         Phone: row.phone,
         Registered: row.registered
       }));

       const csvData = makeCsv(data);
       downloadCsv(csvData, 'Contacts');

       Toaster.successAlt({
         title: 'Contact Exported',
         message: 'Contact List has been Exported'
       });
     }

    return {
      state,
      formatDateRanger,
      contactExportForm,
      handleClose,
      handleSubmit,
    };
  }
};
</script>
<style lang="scss">
.p-dialog.p-component {
  width: 50%;
}
.dp__button{
  display: none;
}

</style>
